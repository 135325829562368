import { Injectable } from '@angular/core';
import { ContainersInfo, Pod } from '../models/pod.model';

export enum ContainerStateEnum {
    POD_TERMINATING = 'Terminating',
    CONTAINER_COMPLETE = 'Running',
    CONTAINER_LOADING = 'Loading',
    CONTAINER_FAILED = 'Failed',
    CONTAINER_UNKNOWN_STATE = 'Unknown',
}

@Injectable({
    providedIn: 'root'
})

export class ContainerStateService {
    public getClassForStateOfInitContainer(containerState: ContainersInfo) {
        const initState = this.getInitContainerState(containerState);
        const statePostfix = initState === ContainerStateEnum.CONTAINER_LOADING ? 'Small' : '';
        return 'stateIndicator' + initState + statePostfix + ' stateIndicatorCircleSmall';
    }

    public getClassForStateOfContainer(containerState: ContainersInfo) {
        const initState = this.getContainerState(containerState);
        const statePostfix = initState === ContainerStateEnum.CONTAINER_LOADING ? 'Small' : '';
        return 'stateIndicator' + initState + statePostfix + ' stateIndicatorCircleSmall';
    }

    public getPodState(pod: Pod): ContainerStateEnum {
        if (pod) {
            if (pod.terminating) {
                return ContainerStateEnum.POD_TERMINATING;
            }
            if (pod.runningTime == -1){
                return ContainerStateEnum.CONTAINER_LOADING;
            }
            const initState = this.checkInitContainersState(pod.initContainersInfo);
            if (initState === ContainerStateEnum.CONTAINER_COMPLETE) {
                return this.checkContainersState(pod.containersInfo);
            } else {
                return initState;
            }
        }
    }



    private checkInitContainersState(containerStates: ContainersInfo[]): ContainerStateEnum {
        for (const containerState of containerStates) {
            const containerStateResult = this.getInitContainerState(containerState);
            if (ContainerStateEnum.CONTAINER_COMPLETE !== containerStateResult) {
                return containerStateResult;
            }
        }

        return ContainerStateEnum.CONTAINER_COMPLETE;
    }



    private checkContainersState(containerStates: ContainersInfo[]): ContainerStateEnum {
        for (const containerState of containerStates) {
            const containerStateResult = this.getContainerState(containerState);
            if (ContainerStateEnum.CONTAINER_COMPLETE !== containerStateResult) {
                return containerStateResult;
            }
        }
        return ContainerStateEnum.CONTAINER_COMPLETE;
    }

    private getInitContainerState(containerState: ContainersInfo) {
        if (!this.isInitContainerCompleted(containerState)) {
            if (this.isPodInitializationRun(containerState) || this.isInitContainerRunning(containerState)) {
                return ContainerStateEnum.CONTAINER_LOADING;
            } else {
                if (this.isInitContainerFailed(containerState)) {
                    return ContainerStateEnum.CONTAINER_FAILED;
                } else {
                    return ContainerStateEnum.CONTAINER_UNKNOWN_STATE;
                }
            }
        } else {
            return ContainerStateEnum.CONTAINER_COMPLETE;
        }
    }

    isInitContainerCompleted(containerState: ContainersInfo): boolean {
        return (containerState.state === 'STATE_TERMINATED' && containerState.ready);
    }


    private isInitContainerFailed(containerState: ContainersInfo) {
        return (containerState.state === 'STATE_WAITING' &&
            containerState.ready === false &&
            (containerState.reason === 'ImagePullBackOff' || containerState.reason === 'ErrImagePull'));
    }

    private isPodInitializationRun(containerState: ContainersInfo) {
        return (containerState.state === 'STATE_WAITING' &&
            containerState.ready === false &&
            containerState.reason === 'PodInitializing');
    }

    private isInitContainerRunning(containerState: ContainersInfo) {
        return (containerState.state === 'STATE_RUNNING' && containerState.ready === false);
    }

    private getContainerState(containerState: ContainersInfo): ContainerStateEnum {
        if (!this.isContainerCompleted(containerState)) {
            if (this.isContainerLoading(containerState)) {
                return ContainerStateEnum.CONTAINER_LOADING;
            }
            if (this.isContainerFailed(containerState)) {
                return ContainerStateEnum.CONTAINER_FAILED;
            } else {
                return ContainerStateEnum.CONTAINER_UNKNOWN_STATE;
            }
        } else {
            return ContainerStateEnum.CONTAINER_COMPLETE;
        }
    }

    private isContainerCompleted(containerState: ContainersInfo): boolean {
        return (containerState.state === 'STATE_RUNNING' && containerState.ready);
    }

    private isContainerFailed(containerState: ContainersInfo): boolean {
        return (containerState.state === 'STATE_WAITING' && containerState.ready === false &&
            (containerState.reason === 'ImagePullBackOff' || containerState.reason === 'ErrImagePull'));
    }

    private isContainerLoading(containerState: ContainersInfo): boolean {
        return ((containerState.state === 'STATE_WAITING' &&
            containerState.ready === false &&
            containerState.reason === '') ||
            (containerState.state === 'STATE_RUNNING' && containerState.ready === false && containerState.message === null));
    }
}
