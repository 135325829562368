import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { WorkspaceUser } from 'src/app/models/workspace-user.model';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { PopupHelper } from 'src/app/helpers/pop-up-helper';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppServerService } from 'src/app/services/app-server.service';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Training } from 'src/app/models/training.model';
import { Course } from 'src/app/models/course.model';
import { AppAdvancedSetting } from 'src/app/models/app-advanced-setting.model';
import { AppServer } from 'src/app/models/app-server.model';
import { TrainingService } from 'src/app/services/training.service';
import { CourseService } from 'src/app/services/course.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SubmitDialogComponent } from '../dialogs/submit-dialog/submit-dialog.component';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {
  datasourceTrainings = new MatTableDataSource<Training>();
  awsWorkspaceForm: UntypedFormGroup;
  dataSourceCourses = new MatTableDataSource<Course>();
  isTrainingCreating = false;
  areCoursesLoaded = false;
  areTrainingsLoaded = false;
  domainPostfixList: string[];
  selectedIndex = new FormControl(0);  
  trainingsColumns = [
    {
      columnDef: 'userMail',
      header: 'User Mail',
      cell: (element: Training) => `${element.userMail}`,
    },
    {
      columnDef: 'courseName',
      header: 'Course Name',
      cell: (element: Training) => `${element.course}`,
    },
    {
      columnDef: 'server',
      header: 'Server Name',
      cell: (element: Training) => `${element.server}`,
    },
    {
      columnDef: 'workspaceId',
      header: 'Workspace ID',
      cell: (element: Training) => `${element.workspaceUser}`,
    },
    {
      columnDef: 'actions',
      header: '',
      cell: (element: Training) => `${element}`,
    },
  ]

  displayedColumnsTrainings = this.trainingsColumns.map(c => c.columnDef);

  constructor( 
    private appServerService: AppServerService,
    private courseService: CourseService, 
    private trainingService: TrainingService, 
    private dialog: MatDialog,
    private popUp: MatSnackBar,
    private popUpHelper: PopupHelper,
    private route: ActivatedRoute,
    private router: Router,
  ) {

    this.refreshTrainings()

    this.appServerService.getDomainPostfixList().subscribe( 
      (data) => {
        this.domainPostfixList = data;
      },
      (error) => {
        this.popUpHelper.showErrorPopUp("There was an error with getting the domain postfix list!\n", error);
      }
    );
    

    this.awsWorkspaceForm = new UntypedFormGroup({
      createWorkspace: new UntypedFormControl(false), 
      course: new UntypedFormControl('',  new UntypedFormControl('', [Validators.required])),
      username: new UntypedFormControl('',  new UntypedFormControl('', [Validators.required]) ),
      endDate: new UntypedFormControl(new Date()),
    });

    
    this.courseService.getCourses().subscribe(
      (data) => {
        this.areCoursesLoaded = true;
        this.dataSourceCourses.data = data;
      },
      (error) => {
        this.popUpHelper.showErrorPopUp("There was an error with getting the courses!\n", error);
      }

    )
   }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectedIndex.value === 0) {
      this.refreshCourses();
    }
  }
  
  ngOnInit(): void {

  }

  isCreatingWorkspace() : boolean {
    return this.awsWorkspaceForm.get('createWorkspace').value;
  }

  createTraining() : void {
    this.isTrainingCreating = true;
    const course = this.awsWorkspaceForm.get('course').value;
    const endDate =  this.awsWorkspaceForm.get('endDate').value;
    const today = new Date();
    if (today >= endDate) {
      this.isTrainingCreating = false;
      this.showErrorMessage("Please pick date later than today");
      return;
    }
    const training = new Training();
    if (this.isCreatingWorkspace()) {
      training.username = this.awsWorkspaceForm.get('username').value;
    }
    training.course = course;
    training.serverEndDate = endDate;
  
    this.popUpHelper.showPopUp('Training will be created!');
    this.trainingService.createTraining(training).subscribe(
      (data) => {
        this.isTrainingCreating = false;
        const tempTrainings = this.datasourceTrainings.data;
        tempTrainings.push(data);
        this.datasourceTrainings.data = tempTrainings;
        this.popUpHelper.showPopUp("Training was created!");

      },
      (error) => {
        this.isTrainingCreating = false;
        this.showErrorMessage("There was an error deleting Server\n" + error.error);
      }
    ); 

  }
  
  
  refreshCourses() {    
    this.areCoursesLoaded = false;
    this.courseService.getCourses().subscribe(
      (data) => {
        this.areCoursesLoaded = true;
        this.dataSourceCourses.data = data;
      },
      (error) => {
        this.areCoursesLoaded = true;
        this.popUpHelper.showErrorPopUp("There was an error with refreshing the courses!\n",  error);
      }
    )
    
  }

  showErrorMessage(message: string) {
    this.dialog.open(InfoDialogComponent, {
      width: '450px',
      data: { "title" : "Error", "message": message},
    });
  }

  deleteTraining(trainingToDelete: Training) {
      const dialogRef = this.openSubmitDialog(
        'Confirm Training deletion',
        'Training is going to be deleted. Are you sure?');

      
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            let serversToDelete = []
            serversToDelete.push(trainingToDelete.server);
            this.popUpHelper.showLoadingPopUp("Training is being deleted!");
            this.trainingService.deleteTraining(trainingToDelete).subscribe(
              (trainingDeleteOk) => {
                this.deleteTrainingLocally(trainingToDelete);
                this.popUpHelper.showPopUp("Training was successfully deleted!");
              },
              (trainingDeleteErr) => {
                this.showErrorMessage("There was an error deleting Training\n" + trainingDeleteErr.error);
                this.popUp.dismiss();
              }
            )
          }
      });
  }


  deleteTrainingLocally(trainingToDelete: Training) {
    let tempTrainings = this.datasourceTrainings.data;
    tempTrainings = tempTrainings.filter(
      (tempTraining) => 
        (tempTraining.id !== trainingToDelete.id)
    );    
    this.datasourceTrainings.data = tempTrainings;
  }


  refreshTrainings() {
    this.areTrainingsLoaded = false
    this.trainingService.getTrainings().subscribe(
      (incommingTrainings) => {
        this.areTrainingsLoaded = true;
        this.datasourceTrainings.data = incommingTrainings;
      },
      (error) => {
        this.areTrainingsLoaded = true;
        this.popUpHelper.showErrorPopUp("There was an error with getting trainings!\n", error);
      }
    );
  }

  handleChange(event: any) {
    this.selectedIndex.setValue(event);
    if (this.selectedIndex.value === 0) {
      this.refreshCourses();
      this.refreshTrainings();
    }
  }

  public errorHandling = (control: string, error: string) => {
    return this.awsWorkspaceForm.controls[control].hasError(error);
  }

  switchToWorkspace(workspace: any) {
    localStorage.setItem('filteredWorkspaceId', workspace.workspaceId);
    this.selectedIndex.setValue(1);
  }

  rerouteToServer(training: any) {
    localStorage.setItem("filterServerName", training.server.serverName);
    this.router.navigate(['/list']);
  }

  
  private openSubmitDialog(title: string, message: string): MatDialogRef<SubmitDialogComponent, any> {
    return this.dialog.open(SubmitDialogComponent, {
        width: '450px',
        data: { title, message }
    });
  }
}

