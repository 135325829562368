import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppServersViewComponent } from './components/app-servers-view/app-servers-view.component';
import { AppServersCreationComponent } from './components/app-servers-creation/app-servers-creation.component';
import { PortalUserViewComponent } from './components/portal-user-view/portal-user-view.component';
import { EventsLogViewComponent } from './components/events-log-view/events-log-view.component';
import { InvoicesViewComponent } from './components/invoices-view/invoices-view.component';
import { VolumesViewComponent } from './components/volumes-view/volumes-view.component';
import { AuthGuard } from './guards/auth.guard';
import { AnnouncementCreationComponent } from './components/announcement-creation/announcement-creation.component';
import { TrainingComponent } from './components/training/training.component';
import { KWConnectTrainingViewComponent } from './components/kwconnect-training-view/kwconnect-training-view.component';
import { InfoViewComponent } from './components/info-view/info-view.component';

const routes: Routes = [
    { path: 'list', component: AppServersViewComponent, canActivate: [AuthGuard]},
    { path: '', redirectTo: 'list', pathMatch: 'full'},
    { path: 'creation', component: AppServersCreationComponent, canActivate: [AuthGuard]},
    { path: 'annoucements', component: AnnouncementCreationComponent, canActivate: [AuthGuard]},
    { path: 'users', component: PortalUserViewComponent, canActivate: [AuthGuard]},
    { path: 'events', component: EventsLogViewComponent, canActivate: [AuthGuard]},
    { path: 'invoices', component: InvoicesViewComponent, canActivate: [AuthGuard]},
    { path: 'volumes', component: VolumesViewComponent, canActivate: [AuthGuard]},
    { path: 'training', component: TrainingComponent, canActivate: [AuthGuard]},
    { path: 'kwconnect', component: KWConnectTrainingViewComponent, canActivate: [AuthGuard]},
    // { path: 'info', loadChildren: () => import('./components/info-view/info-view.module').then(m => m.InfoViewModule),
    { path: 'info', component: InfoViewComponent, canActivate: [AuthGuard] },
];

@NgModule({
    imports: [RouterModule.forRoot(
        routes,
        {
    initialNavigation: isInIframe() ? 'disabled' : undefined,
    relativeLinkResolution: 'legacy'
}
    )],
    exports: [RouterModule]
})
export class AppRoutingModule { }

export function isInIframe() {
    return window !== window.parent && !window.opener;
}
