import { Component, OnInit, Inject } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppServer } from 'src/app/models/app-server.model';
import { UntypedFormGroup, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import { AppServerService } from 'src/app/services/app-server.service';
import { PortalUser } from 'src/app/models/portal-user.model';
import { PortaluserService } from 'src/app/services/portal-user.service';
import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import { AppServerOtherDetailsService } from 'src/app/services/app-server-other-details.service';
import { Pod } from 'src/app/models/pod.model';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-edit-app-server-dialog',
  templateUrl: './edit-app-server-dialog.component.html',
  styleUrls: ['./edit-app-server-dialog.component.scss']
})
export class EditAppServerDialogComponent implements OnInit {
    public editServerForm: UntypedFormGroup;
    public customerForm: UntypedFormGroup;
    internWhitelistSourceRange: string[];
    users: PortalUser[] = null;
    partners: string[] = null;

    advancedSettingsExpanded = false;
    selectedOwner = null;

    selectedAccess = 'intern';
    whitelistSourceRange: string[];
    whilelistSourceRangeChanged = false;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: AppServer,
        private appServerService: AppServerService,
        private appServerOtherDetailsService: AppServerOtherDetailsService,
        private portalUserService: PortaluserService,
        private dialogRef: MatDialogRef<EditAppServerDialogComponent>) {
        }

    ngOnInit() {
        this.loadWhitelistSourceRange();
        this.loadPartners();
        this.loadUsers();
        this.customerForm = null;

        this.editServerForm = new UntypedFormGroup({
            serverOwner: new UntypedFormControl(this.data.serverOwner),
            serverName: new UntypedFormControl(this.data.serverName),
            majorVersion: new UntypedFormControl(this.data.majorVersion),
            minorVersion: new UntypedFormControl(this.data.minorVersion),
            domainPostfix: new UntypedFormControl(this.data.domainPostfix),
            namespace: new UntypedFormControl(this.data.namespace),
            notes: new UntypedFormControl(this.data.notes),
            serverStartDate: new UntypedFormControl(this.data.serverStartDate !== null ? new Date(this.data.serverStartDate) : ''),
            serverEndDate: new UntypedFormControl(this.data.serverEndDate !== null ? new Date(this.data.serverEndDate) : ''),

            contactPersonName: new UntypedFormControl(this.data.contactPersonName),
            contactMail: new UntypedFormControl(this.data.contactMail),
            contactPhone: new UntypedFormControl(this.data.contactPhone),

            price: new UntypedFormControl(this.data.price,
                [Validators.pattern('[0-9]+(\.[0-9][0-9]?)?'), Validators.min(0), Validators.max(1000000)]),
            currency: new UntypedFormControl(this.data.currency),
            partner: new UntypedFormControl(this.data.partner),
            paymentStartDate: new UntypedFormControl(this.data.paymentStartDate !== null ? new Date(this.data.paymentStartDate) : ''),
            paymentEndDate: new UntypedFormControl(this.data.paymentEndDate !== null ? new Date(this.data.paymentEndDate) : ''),
        });        
    
        if (this.data.serverCustomer !== null) {
            this.customerForm = new UntypedFormGroup({
                customerName: new UntypedFormControl(this.data.serverCustomer.customerName, [Validators.maxLength(20)]),
                debitor: new UntypedFormControl(this.data.serverCustomer.debitor),
            });
            this.editServerForm.addControl('serverCustomer', this.customerForm);
        }
    
    }

    compareFunction(o1: any, o2: any) {
        return (o1.mail == o2.mail);
       }

    addWhitelistSourceRangeValue(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        this.whilelistSourceRangeChanged = true;

        if ((value || '').trim()) {
          this.whitelistSourceRange.push(value);
        }

        if (input) {
          input.value = '';
        }
      }

    removeWhitelistSourceRangeValue(value: string): void {
        const index = this.whitelistSourceRange.indexOf(value);
        this.whilelistSourceRangeChanged = true;
        if (index >= 0) {
            this.whitelistSourceRange.splice(index, 1);
        }
    }

    setWhitelistSourceRangeValues(values: string[]): void {
        this.whilelistSourceRangeChanged = true;
        this.whitelistSourceRange = values;
    }

    changeAccess(event) {
        if (event.value === 'intern') {
            this.setWhitelistSourceRangeValues(this.internWhitelistSourceRange);
        }
        if (event.value === 'public') {
            this.setWhitelistSourceRangeValues(['0.0.0.0/0']);
        }
        if (event.value === 'specify') {
            this.setWhitelistSourceRangeValues(this.whitelistSourceRange);
        }
    }

    public isAws() {
        return environment.aws;
    }

    getMaxDate() {
        let now = new Date();
        now.setFullYear(now.getFullYear() + 1);
        return now;
    }

    submitDialog() {
        const appServer = this.editServerForm.value;
        if (!this.whilelistSourceRangeChanged) {
            appServer.podInfo = null;
        } else {
            const pod = new Pod();
            pod.whitelistSourceRange = this.whitelistSourceRange;
            appServer.podInfo = pod;
        }
        this.dialogRef.close(appServer);
    }

    public errorHandling = (control: string, error: string) => {
        return this.editServerForm.controls[control].hasError(error);
    }

    disableConfirm() {
        return this.data.serverCustomer !== null && !this.editServerForm.valid ;
    }

    private loadPartners() {
        this.appServerService.getPartners().subscribe(data => this.partners = data);
    }

    private loadUsers() {
        this.portalUserService.getUsers().subscribe(data => {
            this.users = data;
            this.users.sort((a, b) => a.displayName.localeCompare(b.displayName));
            
        });

    }

    private loadInternWhitelistSourceRange() {
        this.appServerService.getDefaultWhitelistSourceRange().subscribe(
            data => {
                this.internWhitelistSourceRange = data;
                this.typeOfWhiteListSourceRange(this.whitelistSourceRange);
        });
    }

    private typeOfWhiteListSourceRange(whitelistSource: string[]) {
        if (whitelistSource.length === 1 && whitelistSource[0] === '0.0.0.0/0') {
            this.selectedAccess = 'public';
            return;
        } else {
            if (this.internWhitelistSourceRange.length === whitelistSource.length
                && this.internWhitelistSourceRange.every((value, index) => value === whitelistSource[index])) {
                this.selectedAccess = 'intern';
            } else {
                this.selectedAccess = 'specify';
            }
        }
    }

    private loadWhitelistSourceRange() {
        this.appServerOtherDetailsService.getWhitelistSourceRange(this.data).subscribe(value => {
            this.whitelistSourceRange = value;
            this.loadInternWhitelistSourceRange();
        });
    }

}
