import { Component, OnInit, OnDestroy } from '@angular/core';
import { AnnoucementService, Severity } from "src/app/services/announcement.service";
import { AuthService } from './services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import * as objectHash from 'object-hash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnnouncementPopUpComponent } from './components/pop-ups/announcement-pop-up/announcement-pop-up.component';
import { InfoDialogComponent } from './components/dialogs/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'DMEPortalUI';
    public static cookieAnnouncementPrefix = 'ANN_';
    unreadAnnouncements = [];
    allAnnouncements = [];

    constructor(  
      private annoucementService: AnnoucementService,
      private cookieService: CookieService,
      // private authService: AuthService,
      private dialog: MatDialog,
      private snackBar: MatSnackBar

    ) {
    }

    ngOnInit(): void {        
      this.annoucementService.getPlannedAnnoucements().subscribe(
        data => {          
            this.allAnnouncements = data;
            for(let retrievedAnnouncement of data){
            const cookieName = AppComponent.cookieAnnouncementPrefix + retrievedAnnouncement.dateTo + "_" + getHash(retrievedAnnouncement);
            // Does cookie exist or is cookie unread ? If so, add it into unread 
            if(!this.cookieService.check(cookieName) 
                ||
               this.cookieService.get(cookieName) === "false"
              ){
                this.unreadAnnouncements.push(retrievedAnnouncement);
              }
              // this.cookieService.set(AppComponent.cookieAnnouncementPrefix + retrievedAnnouncement.dateTo + "_" + getHash(retrievedAnnouncement), "false");
            }
            if(this.unreadAnnouncements.length !== 0){
              this.snackBar.openFromComponent(AnnouncementPopUpComponent, {
                data: this.unreadAnnouncements
              });
            }

        },
        error => {
          // alert("We're very sorry there has been issue with retrieving Announcements!")
          // this.dialog.open(InfoDialogComponent, {
          //   width: '450px',
          //   data: { "title" :"Error", "message": error},
          // });          
        }
      )
    }


    ngOnDestroy(): void {
    }
}

export function getHash(object: any): string {
  return objectHash(object);
}