import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { LoginInfoHelper } from 'src/app/helpers/login-info-helper';
import { Training } from 'src/app/models/training.model';
import { TrainingService } from 'src/app/services/training.service';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { PopupHelper } from 'src/app/helpers/pop-up-helper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InfoPopUpComponent } from '../pop-ups/info-pop-up/info-pop-up.component';
import { SubmitDialogComponent } from '../dialogs/submit-dialog/submit-dialog.component';

@Component({
  selector: 'kwconnect-training-view',
  templateUrl: './kwconnect-training-view.component.html',
  styleUrls: ['./kwconnect-training-view.component.scss']
})
export class KWConnectTrainingViewComponent implements OnInit {
  public trainingCreating = false;
  public userHasTraining = false;
  public userMail: string;
  public trainingInfo: Training;
  public courseIdNew: string;
  public showTrainingInfo = false;
  public stoppingTraining = false;
  public creatingTraining = false;
  public showCourseInfo = false;


  constructor(public loginLoginInfoHelper: LoginInfoHelper,
              private trainingService: TrainingService,
              private dialog: MatDialog,
              private popUp: MatSnackBar,
              private popUpHelper: PopupHelper,
              private activatedRoute: ActivatedRoute) {
    this.userMail = loginLoginInfoHelper.getLoginUserFromStorage().mail;
    this.loadCourseIdFromParam();
    this.checkTraining();
  }

  ngOnInit(): void {

  }

  private checkTraining() {
    this.trainingService.getTrainingsByLoginUser().subscribe(
      (data) => {
        if (data.length == 0) {
          this.userHasTraining = false;
        } else  {
          this.userHasTraining = true;
          this.trainingInfo = data[0];
        }
      },
      (error) => {
        this.trainingCreating = false;
        this.showErrorMessage("There was an error creating the training!\n" +  error.error);
      }
    )
  }

  stopTraining() {  
    const dialogRef = this.openSubmitDialog(
      'Confirm KWConnect Training deletion',
      'Training with course "' + this.trainingInfo.course.courseName + '" is going to be deleted. Are you sure?');

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.stoppingTraining = true;
        this.popUpHelper.showLoadingPopUp("Training is being deleted!");
        this.trainingService.deleteTraining(this.trainingInfo).subscribe(
          (data) => {
            this.userHasTraining = false;
            this.stoppingTraining = false;
            this.trainingInfo = null;
            this.showCourseInfo = false;
            this.popUpHelper.showPopUp("Training deleted!");
          },
          (error) => {
            this.trainingCreating = false;
            this.showErrorMessage("There was an error getting the training!\n" +  error.error);
          }
        )
      }
    });
  }

  
  private openSubmitDialog(title: string, message: string): MatDialogRef<SubmitDialogComponent, any> {
    return this.dialog.open(SubmitDialogComponent, {
        width: '450px',
        data: { title, message }
    });
  }


  private loadCourseIdFromParam() {
    this.activatedRoute.queryParamMap
      .subscribe((params) => {
        this.courseIdNew = params.get("courseId");
      }
      );
    }
  public createTraining() {
    this.trainingCreating = true;
    this.showTrainingInfo = true;
    this.popUpHelper.showLoadingPopUp("Training is being created!");

    this.activatedRoute.queryParamMap
    .subscribe((params) => {
        this.trainingService.createKWConnectTraining(params.get("courseId")).subscribe(
          (data) => {
            this.trainingCreating = false;
            this.trainingInfo = data
            this.userHasTraining = true;  
            this.popUpHelper.showPopUp("Training was created!");
            // this.popUpHelper.showPopUp("Notification has been sent to email: " + this.userMail);
          },
          (error) => {
            this.trainingCreating = false;
            this.showErrorMessage("There was an error creatiing the training!\n" +  error.error);
            this.popUp.dismiss()
          }
        )
      }
    );
  }

  toggleShowCourseInfo() {
    this.showCourseInfo = !this.showCourseInfo;
  }

  openWorkspaceWebpage() {
    window.open("https://clients.amazonworkspaces.com/", "_blank");
  }

  showErrorMessage(message: string) {
    this.dialog.open(InfoDialogComponent, {
      width: '450px',
      data: { "title" : "Error", "message": message},
    });
  }
  
  showSnackBar(message) {
    this.popUp.openFromComponent(InfoPopUpComponent, {
      data: { message },
      duration: 5000
    });
  }

}

