import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RegistryImageVersion } from '../models/registry-image-version.model';

export class BuildInfo{
    number: number;
    url: string;
    result: string;
    building: boolean;
    timestamp: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ImageRegistryService {
    private REGISTRY_API = 'imageRegistry';
    constructor(private http: HttpClient) { }

    public getRegistryImageVersions(): Observable<RegistryImageVersion[]> {
        return this.http.get<RegistryImageVersion[]>(environment.dmePortalApi + this.REGISTRY_API);
    }

    public build(mtextVersion: string, omsVersion: string, majorMtextVersion: string) {
        const formData = new FormData();
        formData.set('mtextVersion', mtextVersion);
        formData.set('omsVersion', omsVersion);
        formData.set('majorMtextVersion', majorMtextVersion);

        return this.http.post(environment.dmePortalApi + this.REGISTRY_API + '/build', formData);
    }

    public getBuildHistory(majorVersion: string): Observable<BuildInfo[]> {
        const params = new HttpParams()
        .set('majorMtextVersion', majorVersion);
        return this.http.get<BuildInfo[]>(environment.dmePortalApi + this.REGISTRY_API + '/buildHistory', {params});
    }

    public getImagesFromDockerRegistry(majorVersion: string): Observable<string[]> {
        const params = new HttpParams()
            .set('majorMtextVersion', majorVersion);
        return this.http.get<string[]>(environment.dmePortalApi + this.REGISTRY_API + '/imagesFromDockerRegistry', {params});
    }
}
