import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EventLog } from 'src/app/models/event-log.model';
import { PopupHelper } from 'src/app/helpers/pop-up-helper';
import { EventLogService } from 'src/app/services/event-log.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { AppServerService } from 'src/app/services/app-server.service';
import { AppServer } from 'src/app/models/app-server.model';
import { CusotmerService } from 'src/app/services/cusotmer.service';
import { Customer } from 'src/app/models/customer.model';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-events-log-view',
  templateUrl: './events-log-view.component.html',
  styleUrls: ['./events-log-view.component.scss'],
})
export class EventsLogViewComponent implements OnInit {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    tableDataSource: MatTableDataSource<EventLog>;
    public displayedColumns: string[] = ['eventType', 'appServerName', 'eventDate', 'executorName', 'customerName', 'note'];
    customerList: Customer[];
    appServerList: AppServer[];
    eventTypeList: string[];
    filterForm: UntypedFormGroup = null;

    filteredCustomerList: Observable<string[]>;
    filteredAppServerList: Observable<string[]>;
    customerFormControl: UntypedFormControl;
    appServerFormControl: UntypedFormControl;
    eventTypeFormControl: UntypedFormControl;
    dateFromFormControl: UntypedFormControl;
    dateToFormControl: UntypedFormControl;

    eventsLoading = true;
    loadingFailed = false;
    constructor(
        private eventLogService: EventLogService,
        private customerService: CusotmerService,
        private appServerService: AppServerService,
        private popUpHelper: PopupHelper,
    ) { }

    ngOnInit() {
        this.tableDataSource = new MatTableDataSource<EventLog>();
        this.tableDataSource.paginator = this.paginator;

        const serverName = history.state.data;
        history.state.data = null;
        this.addFormControls(serverName);

        this.loadEvents();
        this.loadCustomers();
        this.loadAppServers();
        this.loadEventTypes();
    }

    applyFilter() {
        this.loadEvents();
    }

    clearFilter() {
        this.customerFormControl.setValue(null);
        this.appServerFormControl.setValue(null);
        this.eventTypeFormControl.setValue(null);
    }

    private addFormControls(serverName: string) {
        const dateFrom = new Date();
        dateFrom.setDate(dateFrom.getDate() - 7);
        this.customerFormControl = new UntypedFormControl();
        this.appServerFormControl = new UntypedFormControl(serverName);
        this.eventTypeFormControl = new UntypedFormControl();
        this.dateFromFormControl = new UntypedFormControl(dateFrom);
        this.dateToFormControl = new UntypedFormControl(new Date());

        this.filterForm = new UntypedFormGroup({
            appServerFormControl: this.appServerFormControl,
            customerFormControl: this.customerFormControl,
            eventTypeFormControl: this.eventTypeFormControl,
            dateFromFormControl: this.dateFromFormControl,
            dateToFormControl: this.dateToFormControl
        });
    }



    private loadEvents() {
        this.loadingFailed = false;
        this.eventsLoading = true;
        const appServerName = this.appServerFormControl.value;
        const customerName = this.customerFormControl.value;
        const eventType = this.eventTypeFormControl.value;
        const dateFrom = this.dateFromFormControl.value;
        const dateTo = this.dateToFormControl.value;
        this.eventLogService.getEvents(appServerName, customerName, eventType, dateFrom, dateTo).subscribe(
            data => {
                this.tableDataSource.data = data;
            },
            error => {
                this.popUpHelper.showErrorPopUp('Events loading failed', error);
                this.loadingFailed = true;
            },
            () => this.eventsLoading = false
        );
    }

    private loadEventTypes() {
        this.eventLogService.getEventTypes().subscribe(
            data => {
                this.eventTypeList = data;
            },
            error => {
                this.popUpHelper.showErrorPopUp('Event types loading failed', error);
            }
        );
    }

    private loadAppServers() {
        this.appServerService.getAppServerStoreInDB().subscribe(
            data => {
                this.appServerList = data.sort();
                this.appServerList.sort();
                this.addAppServerFormControl();
            },
            error => {
                this.popUpHelper.showErrorPopUp('Executors loading failed', error);
            }
        );
    }

    private loadCustomers() {
        this.customerService.getCustomers().subscribe(
            data => {
                this.customerList = data;
                this.addCustomerFormControl();
            },
            error => {
                this.popUpHelper.showErrorPopUp('Customers loading failed', error);
            }
        );
    }

    private addAppServerFormControl() {
        this.filteredAppServerList = this.appServerFormControl.valueChanges.pipe(
            startWith(''),
            map(value => this.filterOptions(value, this.appServerList.map(appServer => appServer.serverName))),
        );
    }

    private addCustomerFormControl() {
        this.filteredCustomerList = this.customerFormControl.valueChanges.pipe(
            startWith(''),
            map(value => this.filterOptions(value, this.customerList.map(customer => customer.customerName))),
        );
    }

    private filterOptions(value: string, list: string[]): string[] {
        const filterValue = value.toLowerCase();
        return list.filter(item => item.toLowerCase().includes(filterValue));
    }

}
