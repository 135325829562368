import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
// import { Workspace } from "../models/workspace.model";
import { environment } from "src/environments/environment";
import { WorkspaceUser } from "../models/workspace-user.model";
import { Observable } from "rxjs";
import { UserData } from "../models/user-data.model";
import { Course } from "../models/course.model";
import { DirectoryInfo } from "../models/directory-info.model";

@Injectable({
    providedIn: 'root'
})
export class WorkspaceService {
    private WORKSPACE_API = 'workspace';
    constructor(private http: HttpClient) { }

    public getWorkspaces(): Observable<WorkspaceUser[]> {
        return this.http.get<WorkspaceUser[]>(environment.dmePortalApi + this.WORKSPACE_API + "/list");
    }

    public getCourses(): Observable<Course[]> {
        return this.http.get<Course[]>(environment.dmePortalApi + this.WORKSPACE_API + "/courses");
    }

    public getDirectoryInfo(): Observable<DirectoryInfo> {
        return this.http.get<DirectoryInfo>(environment.dmePortalApi + this.WORKSPACE_API + "/get/directoryInfo");
    }

    public createWorkspace(workspace: WorkspaceUser) : Observable<WorkspaceUser>{
        return this.http.post<WorkspaceUser>(environment.dmePortalApi + this.WORKSPACE_API + "/create", workspace)
    }

    public deleteWorkspace(workspace: WorkspaceUser) {
        return this.http.delete(environment.dmePortalApi + this.WORKSPACE_API + "/delete/" + workspace.workspaceId);
    }
    
}

