import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppServer } from 'src/app/models/app-server.model';
import { BackupRestoreService } from 'src/app/services/backup-restore.service';
import { ServerBackupInfo } from 'src/app/models/server-backup-info.model';

@Component({
  selector: 'app-server-backup-list',
  templateUrl: './server-backup-list.component.html',
  styleUrls: ['./server-backup-list.component.scss']
})
export class ServerBackupListComponent implements OnInit {
    serverBackupList: ServerBackupInfo[];
    public displayedColumns: string[] = ['name', 'date', 'download'];
    constructor(
        public dialogRef: MatDialogRef<ServerBackupListComponent>,
        @Inject(MAT_DIALOG_DATA) public appServer: AppServer,
        private backupRestoreService: BackupRestoreService
    ) {
    }

    ngOnInit(): void {
        this.backupRestoreService.getServerBackupList(this.appServer).subscribe(data => this.serverBackupList = data);
    }

    onCloseClick(): void {

        this.dialogRef.close();
    }

    downloadBackup(serverBackupInfo: ServerBackupInfo) {
        this.backupRestoreService.getS3BackupDownloadLink(serverBackupInfo).subscribe(
            url => window.open(url)
        );
    }

}
