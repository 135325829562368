import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AppServer } from 'src/app/models/app-server.model';
import { SelectionModel } from '@angular/cdk/collections';
import { UntypedFormControl } from '@angular/forms';
import { InvoiceService } from 'src/app/services/invoice.service';
import { PopupHelper } from 'src/app/helpers/pop-up-helper';
import { InvoiceConfirmDialogComponent } from '../../dialogs/invoice-confirm-dialog/invoice-confirm-dialog.component';
import { Invoice } from 'src/app/models/invoice.model';
import { EventLog } from 'src/app/models/event-log.model';
import { AppServerService } from 'src/app/services/app-server.service';
// import { AotCompiler } from '@angular/compiler/AotCompiler';
import { InvoiceItem } from 'src/app/models/invoice-item.model';
import { InvoiceModifyDialogComponent } from '../../dialogs/invoice-modify-dialog/invoice-modify-dialog.component';

@Component({
  selector: 'app-invoice-confirm-view',
  templateUrl: './invoice-confirm-view.component.html',
  styleUrls: ['./invoice-confirm-view.component.scss']
})
export class InvoiceConfirmViewComponent implements OnInit {
    displayedColumns: string[] = ['tableSelection', 'iconInfo', 'name', 'customer', 'debitor', 'price', 'days',
        'calculatePrice', 'paymentStartDate', 'paymentEndDate', 'note', 'invoiceNote'];
    tableDataSource: MatTableDataSource<AppServer>;
    selection = new SelectionModel<AppServer>(true, []);
    dataLoading = false;
    dateFormCotrol = new UntypedFormControl(new Date());
    loadedServers: AppServer[];
    public partners: string[];
    private selectedPartner = null;
    constructor(
        private invoiceService: InvoiceService,
        private appServerService: AppServerService,
        private popUpHelper: PopupHelper,
        private dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.tableDataSource = new MatTableDataSource();
        this.loadNonInvoicedServers();
        this.loadPartners();
    }

    confirmInvoice() {
        const dialogRef =  this.openInvoiceConfirmDialog();
        dialogRef.afterClosed().subscribe((invoice: Invoice) => {
            if (invoice) {
                this.dataLoading = true;
                const ivoiceItems: InvoiceItem[] = [];
                this.selection.selected.forEach(appServer => {
                    let invoiceItem: InvoiceItem = appServer.invoiceItem;
                    const appServerTmp = new AppServer();
                    appServerTmp.serverName = appServer.serverName;
                    invoiceItem.billingServer = appServerTmp;
                    invoiceItem.dayCount = undefined;
                    invoiceItem.currency = appServer.currency;
                    invoiceItem = this.setDateForInvoice(invoiceItem, appServer.paymentStartDate, appServer.paymentEndDate);
                    ivoiceItems.push(invoiceItem);
                });
                invoice.invoiceItemList = ivoiceItems;
                this.invoiceService.confirmInvoice(invoice).subscribe(() => {
                    this.refresh();
                });
            }
        });
    }

    private loadNonInvoicedServers() {
        this.dataLoading = true;
        this.selection.clear();
        const invoiceMonthDate = this.dateFormCotrol.value;
        const firstDay = this.getFirstDayInMonth(invoiceMonthDate);
        const lastDay = this.getLastDayInMonth(invoiceMonthDate);
        this.appServerService.getBillingServers(firstDay, lastDay).subscribe(
            data => {
                data.forEach(appServer => {
                    const invoiceItem = (appServer.invoiceItem === null || appServer.invoiceItem === undefined)
                        ? new InvoiceItem() : appServer.invoiceItem;

                    invoiceItem.dayCount = this.getPaymentDayCount(appServer);
                    invoiceItem.price = (invoiceItem.price === undefined)
                        ? this.getPriceForPaidDays(invoiceItem.dayCount, appServer.price) : invoiceItem.price;
                    appServer.invoiceItem = invoiceItem;
                    if (appServer.serverEvents && appServer.serverEvents.length > 0) {
                        appServer.serverEvents = this.filterEventsForInvoice(appServer.serverEvents);
                    }
                });
                this.loadedServers = data;
                this.tableDataSource.data = data;
                this.filterServersByPartner();
            },
            error => {
                this.popUpHelper.showErrorPopUp('Non invoiced servers loading failed', error);
            },
            () => this.dataLoading = false
        );
    }

    filterEventsForInvoice(eventLogs: EventLog[]) {
        if (eventLogs[eventLogs.length - 1 ].eventType === 'CREATE') {
            eventLogs.pop();
            eventLogs.pop();
        }
        return eventLogs;
    }

    refresh() {
        this.loadNonInvoicedServers();
    }

    private getPaymentDayCount(appServer: AppServer): number {
        const invoiceMonthDate = this.dateFormCotrol.value;
        const endPeriodTime = this.getLastDayInMonth(invoiceMonthDate).getTime();
        const startPeriodTime = (new Date(appServer.paymentStartDate) > this.getFirstDayInMonth(invoiceMonthDate)
            ? new Date(appServer.paymentStartDate) : this.getFirstDayInMonth(invoiceMonthDate)).getTime();
        const days = (endPeriodTime - startPeriodTime) / (1000 * 60 * 60 * 24);
        return Math.ceil(days);
    }

    private getPriceForPaidDays(paidDaysCount: number, serverPrice: number): number {
        const invoiceMonthDate = this.dateFormCotrol.value;
        const daysInMonth = new Date(invoiceMonthDate.getFullYear(), invoiceMonthDate.getMonth() + 1, 0).getDate();
        const priceForPaidDays = paidDaysCount / daysInMonth * serverPrice;
        return Math.ceil(priceForPaidDays);
    }

    closeDatePicker(eventData: any, dp?: any) {
        this.dateFormCotrol.setValue(eventData);
        dp.close();
        this.refresh();
    }

    modifyInvoiceItem(appServer: AppServer) {
        this.openModifyDialog(appServer);
    }

    private setDateForInvoice(invoice: InvoiceItem, paymentStartDate: Date, paymentEndDate: Date): InvoiceItem {
        const invoiceMonthDate = this.dateFormCotrol.value;
        const firstDay = this.getFirstDayInMonth(invoiceMonthDate);
        const lastDay = this.getLastDayInMonth(invoiceMonthDate);
        invoice.startPeriod = paymentStartDate > firstDay ? paymentStartDate : firstDay;
        invoice.endPeriod = (paymentEndDate !== null && paymentEndDate < lastDay) ? paymentEndDate : lastDay;
        return invoice;
    }

    private getFirstDayInMonth(date: Date) {
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    private getLastDayInMonth(date: Date) {
        const lastDayInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
        return new Date(lastDayInMonth.getTime() - 1);
    }
// ************************************************ FILTERS *********************************************/
public changePartnerFilter(partner: string) {
    this.selectedPartner = partner;
    this.filterServersByPartner();
}

private filterServersByPartner() {
    if (this.selectedPartner !== null && this.selectedPartner !== '') {
        this.tableDataSource.data = this.loadedServers.filter(server => server.partner === this.selectedPartner);
    } else {
        this.tableDataSource.data = this.loadedServers;
    }
}
private loadPartners() {
    this.appServerService.getPartners().subscribe(data => this.partners = data);
}

// ************************************************ DIALOGS *********************************************/
    private openInvoiceConfirmDialog() {
        return this.dialog.open(InvoiceConfirmDialogComponent, {
            width: '800px',
            data: new Invoice()
        });
    }

    private openModifyDialog(appServer: AppServer) {
        return this.dialog.open(InvoiceModifyDialogComponent, {
            width: '800px',
            data: appServer
        });
    }
// ************************************************ STATE METHODS *********************************************/
    isInvoiceConfimed(appServer: AppServer) {
        return appServer.invoiceItem.startPeriod !== null && appServer.invoiceItem.startPeriod !== undefined;
    }

    containChanges(appServer: AppServer) {
        return !this.isInvoiceConfimed(appServer) && appServer.serverEvents !== null && appServer.serverEvents.length > 0;
    }

    isPrepareForConfirm(appServer: AppServer) {
        return !this.isInvoiceConfimed(appServer) && (appServer.serverEvents === null || appServer.serverEvents.length === 0)
            && appServer.price !== 0;
    }

    isUnpaidServer(appServer: AppServer) {
        return !this.isInvoiceConfimed(appServer) && (appServer.serverEvents === null || appServer.serverEvents.length === 0)
            && appServer.price === 0;
    }


// ************************************************ SELECTION METHODS *********************************************/
    isAllSelected() {
        let numServersForSelection = 0;
        this.tableDataSource.data.forEach(appServer => {
            if (this.isPrepareForConfirm(appServer) || this.containChanges(appServer)) {
                numServersForSelection++;
            }
        });
        const numSelected = this.selection.selected.length;
        return numSelected === numServersForSelection;
    }

      /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.tableDataSource.data.forEach(appServerRow => {
                if (this.isPrepareForConfirm(appServerRow) || this.containChanges(appServerRow)) {
                    this.selection.select(appServerRow);
                }
            });
    }

}
