import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoices-view',
  templateUrl: './invoices-view.component.html',
  styleUrls: ['./invoices-view.component.scss']
})
export class InvoicesViewComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }
}
