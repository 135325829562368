import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Course } from '../../models/course.model';
import { CourseService } from '../../services/course.service';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InfoPopUpComponent } from '../pop-ups/info-pop-up/info-pop-up.component';
import { PopupHelper } from '../../helpers/pop-up-helper';
import { RegistryImageVersion } from 'src/app/models/registry-image-version.model';
import { ImageRegistryService } from 'src/app/services/image-registry.service';
import { SubmitDialogComponent } from '../dialogs/submit-dialog/submit-dialog.component';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {
  awsWorkspaceForm: UntypedFormGroup;
  areCoursesLoaded = false;
  courseCreating = false;  
  dataSource = new MatTableDataSource<Course>();
  public minorVersions: string[];
  public majorVersions: string[];
  public majorVersionsWiithBuilds: RegistryImageVersion[];


  courseColumns = [
    {
      columnDef: 'courseName',
      header: 'Course Name',
      cell: (element: Course) => `${element.courseName}`,
    },
    {
      columnDef: 'gitURL',
      header: 'gitURL',
      cell: (element: Course) => `${element.gitURL}`,
    },
    {
      columnDef: 'majorVersion',
      header: 'Major Version',
      cell: (element: Course) => `${element.majorVersion}`,
    },
    {
      columnDef: 'minorVersion',
      header: 'Minor Version',
      cell: (element: Course) => `${element.minorVersion}`,
    },
    {
      columnDef: 'vcs',
      header: 'VCS',
      cell: (element: Course) => `${element.vcs}`,
    },
    {
      columnDef: 'actions',
      header: '',
      cell: (element: Course) => `${element}`,
    },
  ];

  displayedColumnsCourses = this.courseColumns.map(c => c.columnDef);

  constructor(
    private courseService: CourseService, 
    private dialog: MatDialog,
    private popUp: MatSnackBar,
    private popUpHelper: PopupHelper,
    private registryService: ImageRegistryService,
  )  {

    this.awsWorkspaceForm = new UntypedFormGroup({
      courseName: new UntypedFormControl('',  new UntypedFormControl('', [Validators.required])),
      gitURL: new UntypedFormControl('',  new UntypedFormControl('', [Validators.required]) ),
      majorVersion: new UntypedFormControl({value: 'Loading', disabled: true}, [Validators.required]),
      minorVersion: new UntypedFormControl({value: 'Loading', disabled: true}),  
      vcs: new UntypedFormControl('',  new UntypedFormControl('', [Validators.required]) ),
    });

    this.registryService.getRegistryImageVersions().subscribe(
      data => {
        this.majorVersions = data.map(mtextVersion => mtextVersion.majorVersion);
        this.majorVersions.sort((a, b) => (a > b ? -1 : 1));
        this.majorVersionsWiithBuilds = data;
        this.awsWorkspaceForm.get('majorVersion').setValue(this.majorVersions[0]);
        this.awsWorkspaceForm.get('majorVersion').enable();
        this.onVersionChange(this.majorVersions[0]);
    });


    courseService.getCourses().subscribe(
      (data) => {
        this.dataSource.data = data;
        this.areCoursesLoaded = true;
      },
      (error) => {
        this.popUpHelper.showErrorPopUp("There was an error with getting the courses!\n", error)
      }
    )
   }


  createCourse() {
    this.courseCreating = true;
    let course = new Course();

    course.courseName = this.awsWorkspaceForm.get('courseName').value;
    course.gitURL = this.awsWorkspaceForm.get('gitURL').value;
    course.majorVersion = this.awsWorkspaceForm.get('majorVersion').value;
    course.minorVersion = this.awsWorkspaceForm.get('minorVersion').value;
    course.vcs = this.awsWorkspaceForm.get('vcs').value;

    this.courseService.createCourse(course).subscribe(
      (data) => {
        this.courseCreating = false;
        let tempCourses = this.dataSource.data;
        tempCourses.push(course);
        this.dataSource.data = tempCourses;
        this.popUpHelper.showPopUp('Course was created!');

      }, 
      (error) => {
        this.courseCreating = false;
        this.showErrorMessage("There was an error with creating the course!\n" + error.error);
      } 
    )
  }

  deleteCourse(courseToDelete: Course) {
    const dialogRef = this.openSubmitDialog(
      'Confirm Course deletion',
      'Course with name "' + courseToDelete.courseName + '" is going to be deleted. Are you sure?');

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.popUpHelper.showLoadingPopUp("Course is deleting!")
        this.courseService.deleteCourse(courseToDelete).subscribe(
          (data) => {
            let tempCourses = this.dataSource.data;
            tempCourses = tempCourses.filter(
              (course) => {
                if(course.courseName !== courseToDelete.courseName) {
                  return course;
                }
              }
            );
            this.dataSource.data = tempCourses;
            this.popUpHelper.showPopUp('Course was deleted!');
          },
          (error) => {
            this.popUp.dismiss();
            this.showErrorMessage("There was an error with deleting the course!\n" + error.error);
          }
        )
      }
    });
  }

  onVersionChange(selectedVersion) {
    this.minorVersions = this.majorVersionsWiithBuilds.filter(version =>
        version.majorVersion === selectedVersion)[0].minorVersions.map(build => build.name);
    this.minorVersions.sort((a, b) => b.localeCompare(a, undefined, {numeric: true, sensitivity: 'base'}));
    this.awsWorkspaceForm.get('minorVersion').setValue(this.minorVersions[0]);
    this.awsWorkspaceForm.get('minorVersion').enable();
  }


  ngOnInit(): void {


  }

  showSnackBar(message) {
    this.popUp.openFromComponent(InfoPopUpComponent, {

      data: { message },
      duration: 5000
    });
  }

  
  showErrorMessage(message: string) {
    this.dialog.open(InfoDialogComponent, {
      width: '450px',
      data: { "title" : "Error", "message": message},
    });

  }


  public errorHandling = (control: string, error: string) => {
    return this.awsWorkspaceForm.controls[control].hasError(error);
  }

  
  private openSubmitDialog(title: string, message: string): MatDialogRef<SubmitDialogComponent, any> {
    return this.dialog.open(SubmitDialogComponent, {
        width: '450px',
        data: { title, message }
    });
  }

}
