import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.scss']
})
export class ChangeLogComponent implements OnInit {
    versionList: VersionItem[];
    constructor() {
        this.versionList = [
            {version: '1.1.0', date: '9.12.2021', changes: [
                'Replacement of Microsoft auth by Keycloak auth',
                'Sorting of minor versions in select inputs',
                'Add filter for loading servers,',
                'Add long loading servers to tab with failed servers',
                'Option for backup/restore only mtext-init dir',
                'Clean all data before restore (including mtext-init)',
                'Sorting of users',

            ]},
            {version: '1.0.4', date: '24.2.2020', changes: [
                'Add the option to back up the last revision of vcs (git/svn)',
                'Change expiry notification time from 1:00 to 9:00',
                'Change welcome page security',
                'Add db tools and document portal activation',
                'Restarting the entire app server instead of restarting only wildfly container after server restore',
                'Setting a maximum server end date',
                'Add midnight backup logs to event logger',
                'Add user last activity to users view',
                'Add logging for instance of app server (Instance log)',
                'Hide ip list in server edit for non aws portal',
                'Server list refresh only for active tab',
                'Bugfix: editing stopped server information',

            ]},
            {version: '1.0.3', date: '5.1.2020', changes: [
                'Add adnvanced option for git as version control system',
                'Enter dates by date pickers only. The date cannot be empty.',
                'Add for admin another confirm  dialog for restore and deleting servers',
                'Add only standalone config file from wildfly configuration folder to backup',
                'Add option for volume data cleaning',
                'Show default backup files for restore by mtext version and type of  version control system ',
                'Change the name of the downloaded backup file according to the server name',
                'Midnight backup of the portal',
                'Bugfix: Fixed Secure welcome page'
            ]},
            {version: '1.0.2', date: '26.11.2020', changes: [
                'Different color theme for AWS',
                'NFS volumes size monitoring',
                'Liveness checker (in case of an availability problem, the portal will be automatically restarted)',
                'Add admin list to contacts',
                'Bugfix: Fixed a bug when buildig an other version mtext version with major version 6.12'
            ]},
            {version: '1.0.1', date: '11.11.2020', changes: [
                'Option for build own mtext version',
                'Option for apply of mtext-init after restore',
            ]},
            {version: '1.0.0', date: '10.10.2020', changes: [
                'Midnight backup of running servers to saved s3 from aws',
                'Welcome page security option',
                'Automatic shutdown of the servers after expiration',
                'Sending notification emails 3 days before expiration of server',
                'Show last mtext activity in application server detail',
                'Hide advenced settings for backup, restore, create server view',
                'Backup selection to advance settings',
                'Filter for invoices and users',
                'Info view',
                'Log backup and restore actions'
            ]},
            {version: '1.0.0-beta', date: '17.8.2020', changes: ['Init portal']}
        ];
     }
    ngOnInit(): void {
    }

}

export class VersionItem {
    version: string;
    date: string;
    changes: string[];
}
