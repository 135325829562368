import { Component, Inject, OnInit } from '@angular/core';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppServer } from 'src/app/models/app-server.model';
import { saveFile } from 'src/app/helpers/file-download-helper';
import { PopupHelper } from 'src/app/helpers/pop-up-helper';
import { AppServerService } from 'src/app/services/app-server.service';

@Component({
  selector: 'app-restore-error-dialog',
  templateUrl: './restore-error-dialog.component.html',
  styleUrls: ['./restore-error-dialog.component.scss']
})
export class RestoreErrorDialogComponent {

  constructor(
    private popUpHelper: PopupHelper,
    private appServerService: AppServerService,
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onCloseClick(): void {
    this.dialogRef.close();
  }


  downloadInstanceLog(): void{
    this.appServerService.getServerLog(this.data.appServer).subscribe(
      data => {
          this.popUpHelper.showPopUp('Server log file started downloading');
          saveFile(data, this.data.appServer.serverName + '.log');
      },
      error => this.popUpHelper.showErrorPopUp('Server log file error', error)
  );
  }

}

export interface DialogData {
  title: string;
  message: string;
  appServer: AppServer;
}
