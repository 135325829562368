import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AppServer } from 'src/app/models/app-server.model';
import { SelectionModel } from '@angular/cdk/collections';
import { InvoiceService } from 'src/app/services/invoice.service';
import { PopupHelper } from 'src/app/helpers/pop-up-helper';
import { Invoice } from 'src/app/models/invoice.model';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { InvoiceConfirmDialogComponent } from '../../dialogs/invoice-confirm-dialog/invoice-confirm-dialog.component';
import { SubmitDialogComponent } from '../../dialogs/submit-dialog/submit-dialog.component';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-invoice-history',
  templateUrl: './invoice-history.component.html',
  styleUrls: ['./invoice-history.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded  <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class InvoiceHistoryComponent implements OnInit {
    displayedColumns: string[] = ['invoiceNumber', 'confirmDate', 'totalPrice', 'serverCount', 'note', 'actions'];
    tableDataSource: MatTableDataSource<Invoice>;
    expandedElement: AppServer | null;
    invoiceItemsColumns: string[] = ['serverName', 'customerName', 'debitor', 'price', 'startPeriod', 'endPeriod', 'note'];
    hoverInvoice: Invoice;

    selection = new SelectionModel<AppServer>(true, []);
    dataLoading = false;
    date = new Date();

    filterForm: UntypedFormGroup = null;
    filteredInvoiceNumberList: Observable<string[]>;
    invoiceNumberFormControl: UntypedFormControl;
    dateFromFormControl: UntypedFormControl;
    dateToFormControl: UntypedFormControl;
    invoicesList: Invoice[];

    constructor(
        private invoiceService: InvoiceService,
        private popUpHelper: PopupHelper,
        private dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.tableDataSource = new MatTableDataSource();

        this.invoiceNumberFormControl = new UntypedFormControl();
        this.dateFromFormControl = new UntypedFormControl(this.getFirstDayInMonth(new Date()));
        this.dateToFormControl = new UntypedFormControl(new Date());

        this.filteredInvoiceNumberList = this.invoiceNumberFormControl.valueChanges.pipe(
            startWith(''),
            map(value => this.filterInvoices(value, this.tableDataSource.data.map(invoice => invoice.invoiceNumber))),
        );

        this.dateFromFormControl.valueChanges.subscribe(() => this.refresh());
        this.dateToFormControl.valueChanges.subscribe(() => this.refresh());

        this.filterForm = new UntypedFormGroup({
            invoiceNumberFormControl: this.invoiceNumberFormControl,
            dateFromFormControl: this.dateFromFormControl,
            dateToFormControl: this.dateToFormControl
        });

        this.loadNInvoices();
    }

    refresh() {
        this.loadNInvoices();
    }


    private loadNInvoices() {
        this.dataLoading = true;
        this.invoiceService.getInvoices(this.dateFromFormControl.value, this.dateToFormControl.value).subscribe(
            data => {
                data.forEach(invoice => {
                    invoice.totalPrice = 0;
                    invoice.serverCount = 0;
                    invoice.invoiceItemList.forEach(invoiceItem => {
                        invoice.totalPrice += invoiceItem.price;
                        invoice.serverCount++;
                    });
                });
                this.invoicesList = data;
                this.tableDataSource.data = data;
                this.popUpHelper.showPopUp('Invoices loaded');
            },
            error => {
                this.popUpHelper.showErrorPopUp('Non invoiced servers loading failed', error);
            },
            () => this.dataLoading = false
        );
    }

/************************************ INVOICE ACTIONS  **************************************************/
    public showActions(event: Event, invoice: Invoice) {
        this.hoverInvoice = invoice;
    }

    public hiddenActions() {
        this.hoverInvoice = null;
    }
    public editInvoice(invoice: Invoice) {
        const dialogRef =  this.openInvoiceConfirmDialog(invoice);
        dialogRef.afterClosed().subscribe((result: Invoice) => {
            if (result) {
                this.popUpHelper.showLoadingPopUp('Invoice edit confirming');
                result.totalPrice = undefined;
                result.serverCount = undefined;
                this.invoiceService.editInvoice(result).subscribe(() => {
                    this.refresh();
                });
            }
        });
    }

    public removeInvoice(invoice: Invoice) {
        const dialogRef = this.openSubmitDialog(
            'Confirm invoice deletation',
            '');
        dialogRef.afterClosed().subscribe(result => {
            this.popUpHelper.showLoadingPopUp('Invoice deleting');
            if (result) {
                this.invoiceService.deleteInvoice(invoice).subscribe(() => {
                    this.refresh();
                });
            }
        });
    }

    public exportInvoice() {

    }

/********************************************** DIALOGS  **************************************************/
    private openInvoiceConfirmDialog(invoice: Invoice) {
        return this.dialog.open(InvoiceConfirmDialogComponent, {
            width: '800px',
            data: invoice
        });
    }

    private openSubmitDialog(title: string, message: string): MatDialogRef<SubmitDialogComponent, any> {
        return this.dialog.open(SubmitDialogComponent, {
            width: '450px',
            data: { title, message }
        });
    }


/************************************************ FILTER METHODS ****************************************************/
    clearFilter() {
        this.invoiceNumberFormControl.setValue(null);
        const date = new Date();
        this.dateFromFormControl.setValue(this.getFirstDayInMonth(date));
        this.dateToFormControl.setValue(date);
    }

    private getFirstDayInMonth(date: Date) {
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    private filterInvoices(value: string, list: string[]): string[] {
        const filterValue = value.toLowerCase();
        if (this.invoicesList) {
            this.tableDataSource.data = this.invoicesList.filter(
                invoice => invoice.invoiceNumber !== null && invoice.invoiceNumber !== '' && invoice.invoiceNumber.startsWith(filterValue)
            );
        }
        return list.filter(item => item && item !== '' && item.toLowerCase().startsWith(filterValue));
    }

}
