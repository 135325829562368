import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppServer } from '../models/app-server.model';
import { Observable } from 'rxjs/internal/Observable';
import { ServerBackupInfo } from '../models/server-backup-info.model';

@Injectable({
    providedIn: 'root'
})
export class BackupRestoreService {
    private BACKUP_RESTORE_API = 'backuprestore';
    constructor(private http: HttpClient) { }

    public uploadBackupFile(fileToUpload: File) {
        const formData: FormData = new FormData();
        formData.append('uploadFile', fileToUpload, fileToUpload.name);
        return this.http.post<any>(
            environment.dmePortalApi + this.BACKUP_RESTORE_API + '/uploadFile',
            formData,
            {
                reportProgress: true,
                observe: 'events'
            }
        );
    }

    public restorePod(appServer: AppServer, defaulBackupName: string, restoreWildfly: string, restoreVCS: string,
                      restorePostgres: string, restoreMTextInit: string): Observable<any> {
        let params = new HttpParams()
            .set('appServerName', appServer.serverName)
            .set('appUrl', appServer.serverName + '.' + appServer.domainPostfix)
            .set('majorVersion', appServer.majorVersion)
            .set('restoreWildfly', restoreWildfly)
            .set('restoreVCS', restoreVCS.valueOf())
            .set('restorePostgres', restorePostgres)
            .set('restoreMTextInit', restoreMTextInit);

        if (defaulBackupName !== null) {
            params = params.set('defaultBackupName', defaulBackupName);
        }
        return this.http.get<any>(
            environment.dmePortalApi + this.BACKUP_RESTORE_API + '/restore',
            { params }
        );
    }

    public backupPod(appServer: AppServer, backupWildfly: boolean, backupVCS: boolean, backupPostgres: boolean, backupEar: boolean, backupOnlyLastRevision: boolean, backupMTextInit: boolean): Observable<any> {
        const params = new HttpParams()
            .set('appServerName', appServer.serverName)
            .set('backupWildfly', backupWildfly.toString())
            .set('backupVCS', backupVCS.toString())
            .set('backupPostgres', backupPostgres.toString())
            .set('backupEar', backupEar.toString())
            .set('backupOnlyLastRevision', backupOnlyLastRevision.toString())
            .set('backupMTextInit', backupMTextInit.toString());

        return this.http.get<any>(environment.dmePortalApi + this.BACKUP_RESTORE_API + '/backup',
            { responseType: 'blob' as 'json', params });
    }

    public prepareBackupFromServer(appServer: AppServer): Observable<any> {


        const params = new HttpParams()
            .set('namespace', appServer.podInfo.namespace)
            .set('appServerName', appServer.serverName)
            .set('majorVersion', appServer.majorVersion)
            .set('minorVersion', appServer.minorVersion);
        return this.http.get<any>(environment.dmePortalApi + this.BACKUP_RESTORE_API + '/prepareBackupFromServer',
            { params });
    }


    public getDefaultBackupsNames(appServer: AppServer) {
        const params = new HttpParams()
            .set('appServerName', appServer.serverName)
            .set('majorVersion', appServer.majorVersion);
        return this.http.get<string[]>(environment.dmePortalApi + this.BACKUP_RESTORE_API + '/defaultBackups', { params });
    }

    public getServerBackupList(appServer: AppServer) {
        const params = new HttpParams()
            .set('serverName', appServer.serverName);
        return this.http.get<ServerBackupInfo[]>(environment.dmePortalApi + this.BACKUP_RESTORE_API + '/serverBackupList', { params });
    }

    public getS3BackupDownloadLink(serverBackupInfo: ServerBackupInfo) {
        const params = new HttpParams()
            .set('s3ObjectKey', serverBackupInfo.s3ObjectKey)
            .set('versionId', serverBackupInfo.versionId);
        const requestOptions: object = {responseType: 'text' , params};
        return this.http.get<string>(environment.dmePortalApi + this.BACKUP_RESTORE_API + '/s3FileDownloadUrl',
            requestOptions);
    }
}
