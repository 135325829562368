import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { PopupHelper } from 'src/app/helpers/pop-up-helper';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { AppServerService } from 'src/app/services/app-server.service';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { WorkspaceUser } from 'src/app/models/workspace-user.model';
import { DirectoryInfo } from 'src/app/models/directory-info.model';
import { InfoPopUpComponent } from '../pop-ups/info-pop-up/info-pop-up.component';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SubmitDialogComponent } from '../dialogs/submit-dialog/submit-dialog.component';

@Component({
  selector: 'app-workspace-user',
  templateUrl: './workspace-user.component.html',
  styleUrls: ['./workspace-user.component.scss']
})
export class WorkspaceUserComponent implements OnInit, OnChanges {

  dataSourceWorkspace = new MatTableDataSource<WorkspaceUser>();
  loadedWorkspaces: WorkspaceUser[] = [];
  directoryInfo: DirectoryInfo;
  domainPostfixList: string[];
  areCoursesLoaded = false;
  areLoadedUsers = false;
  areWorkspacesLoaded = false;
  isDirectoryInfoLoaded = false;
  awsWsChecked: boolean = false;
  awsWorkspaceForm: UntypedFormGroup;
  awsWorkspaceCheck = new FormControl(false);
  workspaceCreating = false;
  workspacesRefreshing: boolean = false;
  filteredWorkspaceId:  Observable<string[]>
  workspaceIds: string[];
  workspaceIdSearchForm = new FormControl(''); 
  filteredFormGroup: UntypedFormGroup;

  @Input() selectedIndex: FormControl | null;
  @Input() selectedValue: number | null;

  workspaceColumns = [
    {
      columnDef: 'state',
      header: 'State',
      cell: (element: WorkspaceUser) => `${element.state}`,
    },
    {
      columnDef: 'username',
      header: 'Username',
      cell: (element: WorkspaceUser) => `${element.username}`,
    },
    {
      columnDef: 'password',
      header: 'Password',
      cell: (element: WorkspaceUser) => `${element.password}`,
    },
    {
      columnDef: 'workspaceId',
      header: 'workspaceId',
      cell: (element: WorkspaceUser) => `${element.workspaceId}`,
    },
  
  ]
  displayedColumnsWorkpace = this.workspaceColumns.map(c => c.columnDef);
  
  constructor(
    private workspaceService: WorkspaceService,
    private dialog: MatDialog,
    private popUp: MatSnackBar,
    private popUpHelper: PopupHelper,
    private appServerService: AppServerService,

    ){
      this.appServerService.getDomainPostfixList().subscribe( data => {
        this.domainPostfixList = data;
      });
      this.workspaceService.getDirectoryInfo().subscribe(
        (data) => {
            this.directoryInfo = data;
            this.isDirectoryInfoLoaded = true;
          },
          (error) => {
            this.isDirectoryInfoLoaded = true;
            this.popUpHelper.showErrorPopUp("There was an error with getting the Directory info!" + error.error);
        }
      );

      this.awsWorkspaceForm = new UntypedFormGroup({
        courses: new UntypedFormControl('',  new UntypedFormControl('', [Validators.required])),
        username: new UntypedFormControl('',  new UntypedFormControl('', [Validators.required]) ),
        endDate: new UntypedFormControl(new Date()),

        awsChecked: this.awsWorkspaceCheck,
      });

      this.filteredFormGroup = new UntypedFormGroup({
        workspaceIdSearchForm: this.workspaceIdSearchForm,
      })
      this.reloadWorkspaces();




  }


  reloadWorkspaces() {
    this.workspacesRefreshing = true;
    let requestedWorkspaceId = localStorage.getItem('filteredWorkspaceId');
    if (requestedWorkspaceId && requestedWorkspaceId !== '') {
      this.workspaceIdSearchForm.setValue(requestedWorkspaceId);
      localStorage.removeItem('filteredWorkspaceId');
    } else {
      this.workspaceIdSearchForm.setValue('');
      requestedWorkspaceId = '';
    }
    this.workspaceService.getWorkspaces().subscribe(
      (data) => {
        this.workspacesRefreshing = false;
        this.loadedWorkspaces = data;
        this.dataSourceWorkspace.data = data;
        this.areWorkspacesLoaded = true;

        this.workspaceIds = this.dataSourceWorkspace.data
              .map(workspace => workspace.workspaceId)
              .filter((workspaceId, index, self) => workspaceId !== null && index === self.indexOf(workspaceId));

        this.filteredWorkspaceId = this.workspaceIdSearchForm.valueChanges.pipe(
            startWith(requestedWorkspaceId),
            map(searchValue => this.filterOptions(searchValue, this.workspaceIds))
        );

      },
      (error) => {
        this.workspacesRefreshing = false;
        this.dialog.open(InfoDialogComponent, {
          width: '450px',
          data: { "title" :"Error", "message": "There was an error with getting the workspaces!\n" + error.error },

        });
      }
    );
  }

  deleteWorkspace(workspaceToDelete : WorkspaceUser){
    const dialogRef = this.openSubmitDialog(
      'Confirm Workspace deletion',
      'Workspace with WorkspaceID "' + workspaceToDelete.workspaceId + '" is going to be deleted. Are you sure?');
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.showSnackBar("Workspace is being deleted!")
          this.workspaceService.deleteWorkspace(workspaceToDelete).subscribe(
            (data) => {
              let tempWorkspaces = this.dataSourceWorkspace.data;
      
              tempWorkspaces = tempWorkspaces.filter( (workspace) => {
      
                if(workspace.workspaceId !== workspaceToDelete.workspaceId){
                  return workspace;
                }
              })
              this.dataSourceWorkspace.data = tempWorkspaces;
              this.popUpHelper.showPopUp('Workspace was deleted!');
            },
            (error) => {
      
              this.dialog.open(InfoDialogComponent, {
                width: '450px',
                data: { "title" :"Error", "message": "There was a problem with deleting workspace!\n" +  error.error },
              });
            }
          )
        }
    });


    
  }

  showSnackBar(message) {
    this.popUp.openFromComponent(InfoPopUpComponent, {
      data: { message },
      duration: 5000
    });
  }

  openWorkspaceLogin() {
    window.open("https://eu-west-1.webclient.amazonworkspaces.com/login", "_blank");
  }

  openWorkspaceClients() {
    window.open("https://clients.amazonworkspaces.com/", "_blank");
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectedIndex.value === 1) {
      this.reloadWorkspaces();
    }
  }

  
  private openSubmitDialog(title: string, message: string): MatDialogRef<SubmitDialogComponent, any> {
    return this.dialog.open(SubmitDialogComponent, {
        width: '450px',
        data: { title, message }
    });
  }

  ngOnInit() {
  }

  public errorHandling = (control: string, error: string) => {
    return this.awsWorkspaceForm.controls[control].hasError(error);
  }

  private filterOptions(value: any, list: string[]): string[] {
    const filterValue = value.toLowerCase();
    const filteredWorkspaces = list.filter(item => item.toLowerCase().includes(filterValue)); 
    this.dataSourceWorkspace.data = this.loadedWorkspaces.filter((workspace) => workspace.workspaceId.includes(value));    
    return filteredWorkspaces;
  }

}
