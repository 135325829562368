import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Invoice } from 'src/app/models/invoice.model';

@Component({
  selector: 'app-invoice-confirm-dialog',
  templateUrl: './invoice-confirm-dialog.component.html',
  styleUrls: ['./invoice-confirm-dialog.component.scss']
})
export class InvoiceConfirmDialogComponent implements OnInit {
    invoiceForm: UntypedFormGroup;
    invoiceNumberFormControl: UntypedFormControl;
    noteFormControl: UntypedFormControl;

    constructor(
        public dialogRef: MatDialogRef<InvoiceConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private invoice: Invoice,
    ) { }

    ngOnInit() {
        this.invoiceNumberFormControl = new UntypedFormControl(this.invoice.invoiceNumber);
        this.noteFormControl = new UntypedFormControl(this.invoice.note);
        this.invoiceForm = new UntypedFormGroup({
            invoiceNumberFormControl: this.invoiceNumberFormControl,
            noteFormControl: this.noteFormControl
        });
    }

    submit() {
        this.invoice.invoiceNumber = this.invoiceNumberFormControl.value;
        this.invoice.note = this.noteFormControl.value;
        this.invoice.confirmDate = this.invoice.confirmDate === undefined ? new Date() : this.invoice.confirmDate;
        this.dialogRef.close(this.invoice);
    }

}
