import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PopupHelper } from 'src/app/helpers/pop-up-helper';
import { saveFile } from 'src/app/helpers/file-download-helper';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { BackupRestoreService } from 'src/app/services/backup-restore.service';
import { AppServer } from 'src/app/models/app-server.model';

@Component({
  selector: 'app-backup-selection-dialog',
  templateUrl: './backup-selection-dialog.component.html',
  styleUrls: ['./backup-selection-dialog.component.scss']
})
export class BackupSelectionDialogComponent implements OnInit {
    wildflySelected = true;
    vcsSelected = true;
    postgresSelected = true;
    mtextInitSelected = true;
    backupOnlyLastRevisionSelected = false;
    earSelected =  false;
    selectedOption = 'default';
    constructor(
        @Inject(MAT_DIALOG_DATA) public appServer: AppServer,
        private backupRestoreService: BackupRestoreService,
        private dialog: MatDialog,
        private popUpHelper: PopupHelper,
        public dialogRef: MatDialogRef<InfoDialogComponent>,
    ) { }

    ngOnInit(): void {
    }

    submit() {
        this.openInfoDialog('File download', 'Backup download may take several minutes.');
        this.popUpHelper.showLoadingPopUp('Preparing backup file');

        this.backupRestoreService.backupPod(this.appServer, this.wildflySelected, this.vcsSelected,
            this.postgresSelected, this.earSelected, this.backupOnlyLastRevisionSelected, this.mtextInitSelected).subscribe(
            data => {
                this.popUpHelper.showPopUp('Backup file started downloading');
                saveFile(data, this.appServer.serverName + '_backup.zip');
            },
            error => this.popUpHelper.showErrorPopUp('Backup server error', error)
        );
        this.dialogRef.close();
    }

    private openInfoDialog(title: string, message: string): void {
        this.dialog.open(InfoDialogComponent, {
            width: '450px',
            data: { title, message }
        });
    }

    setDefaultBackupSettings() {
        this.wildflySelected = true;
        this.vcsSelected = true;
        this.postgresSelected = true;
        this.earSelected =  false;
        this.backupOnlyLastRevisionSelected = false;
        this.mtextInitSelected = true;
    }

}
