import { MatSnackBar } from '@angular/material/snack-bar';
import { InfoPopUpComponent } from '../components/pop-ups/info-pop-up/info-pop-up.component';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PopupHelper {
    constructor(
        private popUp: MatSnackBar,
    ) {}

    public showErrorPopUp(message: string, error?: any) {
        this.showPopUp(message, 'error');
        console.error(message);
        console.error(error);
    }

    public showLoadingPopUp(message: string, type?: string) {
        this.popUp.openFromComponent(InfoPopUpComponent, {
            data: { message, type: 'loading' },
            duration: 1000 * 60 * 10
        });
    }

    public showPopUp(message: string, type?: string) {
        this.popUp.openFromComponent(InfoPopUpComponent, {
            data: { message, type },
            duration: 5000
        });
    }
}
