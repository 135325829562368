import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegistryImageVersion } from 'src/app/models/registry-image-version.model';
import { ImageRegistryService } from 'src/app/services/image-registry.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-select-version-dialog',
    templateUrl: './select-version-dialog.component.html',
    styleUrls: ['./select-version-dialog.component.scss']
})
export class SelectVersionDialogComponent implements OnInit {
    public version: RegistryImageVersion;
    public minorVersionSelection: UntypedFormGroup;
    public minorVersionFormControl: UntypedFormControl = new UntypedFormControl();
    constructor(
        @Inject(MAT_DIALOG_DATA) public majorVersion: string,
        private imageRegistryService: ImageRegistryService,

    ) { }

    ngOnInit() {
        this.minorVersionSelection = new UntypedFormGroup({
            minorVersionFormControl: this.minorVersionFormControl,
        });
        this.imageRegistryService.getRegistryImageVersions().subscribe(
            versions => {
                this.version = versions.find(versionInfo => versionInfo.majorVersion === this.majorVersion);
                this.version.minorVersions.sort((a, b) => b.name.localeCompare(a.name, undefined, {numeric: true, sensitivity: 'base'}));
                this.minorVersionFormControl.setValue(this.version.minorVersions[0].name);
            }
        );
    }

}
