import { Injectable } from '@angular/core';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PortaluserService } from '../services/portal-user.service';
import { LoginInfoHelper } from '../helpers/login-info-helper';


@Injectable({
    providedIn: 'root',
  })
  export class AuthGuard extends KeycloakAuthGuard {
    constructor(
      protected readonly router: Router,
      protected readonly keycloak: KeycloakService,
      protected readonly userService: PortaluserService,
      protected readonly loginHelper: LoginInfoHelper
    ) {
      super(router, keycloak);
    }

    public async isAccessAllowed(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        // Force the user to log in if currently unauthenticated.
        if (!this.authenticated) {
            await this.keycloak.login({
                redirectUri: window.location.origin,
            });
        }
        this.userService.getMe().subscribe(user => this.loginHelper.setLoginUserToStorage(user));
        return true;
    }
  }
