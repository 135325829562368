import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EventLog } from '../models/event-log.model';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class EventLogService {
    private EVENT_API = 'event';
    constructor(private http: HttpClient) { }

    public getEvents(appSeverName: string, customerName: string, eventType: string, dateFrom: Date, dateTo: Date): Observable<EventLog[]> {
        let params = new HttpParams();
        if (appSeverName !== null) {
            params = params.set('appSeverName', appSeverName);
        }
        if (customerName !== null) {
            params = params.set('customerName', customerName);
        }
        if (eventType !== null) {
            params = params.set('eventType', eventType);
        }

        params = params.set('dateFrom', formatDate(dateFrom, 'dd/MM/yyyy', 'en-US'));
        params = params.set('dateTo', formatDate(dateTo, 'dd/MM/yyyy', 'en-US'));
        return this.http.get<EventLog[]>(environment.dmePortalApi + this.EVENT_API + '/list', {params});
    }

    public getEventTypes() {
        return this.http.get<string[]>(environment.dmePortalApi + this.EVENT_API + '/types');
    }
}
