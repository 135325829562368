import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PortaluserService } from 'src/app/services/portal-user.service';
import { MatTableDataSource } from '@angular/material/table';
import { PortalUser } from 'src/app/models/portal-user.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit, OnChanges {
    public tableDataSource: MatTableDataSource<PortalUser>;
    loadedUsers: PortalUser[];
    public displayedColumns: string[] = ['displayName', 'mail'];
    
    @Input() selectedIndex: FormControl | null;
    @Input() selectedValue: number | null;

    constructor(
        private portalUserService: PortaluserService,
    ) {}

    ngOnInit(): void {
        this.tableDataSource = new MatTableDataSource();
    }

    private loadAdmins() {
        this.portalUserService.getAdminContacts().subscribe(
            data => {
                data.sort((a, b) => a.displayName.localeCompare(b.displayName));
                this.tableDataSource.data = data;
            },
            err => {
                
            }
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.selectedIndex.value === 2) {
          this.loadAdmins();
        }
      }


}
