import { Component, OnInit, Input } from '@angular/core';
import { AppServer } from 'src/app/models/app-server.model';
import { ServerBackupListComponent } from '../../dialogs/server-backup-list/server-backup-list.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PopupHelper } from 'src/app/helpers/pop-up-helper';
import { ContainerStateService } from 'src/app/services/container-state.service';
import { AppServerOtherDetailsService } from 'src/app/services/app-server-other-details.service';
import { VolumeInfo } from 'src/app/models/volume-info.model';
import { VolumeService } from 'src/app/services/volume.service';
import { environment } from 'src/environments/environment';
import { SubmitDialogComponent } from '../../dialogs/submit-dialog/submit-dialog.component';
import { AppServerService } from 'src/app/services/app-server.service';
import { saveFile } from 'src/app/helpers/file-download-helper';
import { InfoDialogComponent } from '../../dialogs/info-dialog/info-dialog.component';
import { TrainingService } from 'src/app/services/training.service';
import { Training } from 'src/app/models/training.model';
import { LoginInfoHelper } from 'src/app/helpers/login-info-helper';

@Component({
  selector: 'app-app-server-detail',
  templateUrl: './app-server-detail.component.html',
  styleUrls: ['./app-server-detail.component.scss', '../app-servers-view.component.scss']
})
export class AppServerDetailComponent implements OnInit {
    @Input() appServer: AppServer;
    showMoreDetails = false;
    mtextLogLastModified: Date;
    whitelistSourceRange: string[];
    debugPort: number;
    jbossPort: number;
    jbossConsolePort: number;
    ports: string[];
    volumeSpace: VolumeInfo;
    maxVolumeSpace: number;
    isDbToolsActive = false;
    isDocumentPortalActive = false;
    isWelcomePageSecureAvaible = false;
    runningTime: number;
    training: Training;

    constructor(
        private dialog: MatDialog,
        private popUpHelper: PopupHelper,
        public containerStateSevice: ContainerStateService,
        private appServerOtherDetailsService: AppServerOtherDetailsService,
        private appServerService: AppServerService,
        private volumeService: VolumeService,
        private trainingService: TrainingService,
        public loginInfoHelper: LoginInfoHelper,
    ) { }

    ngOnInit(): void {
        if (this.appServer.podInfo !== null && this.appServer.podInfo !== undefined) {
            this.loadFromLocalStorage();
            this.loadWhitelistSourceRange();
            this.loadPorts();
            this.isWelcomePageSecure();
            this.chechIsWelcomePageSecureAvaible();
            this.checkDbToolsActive();
            this.checkDocumentPortalActive();
            if (this.loginInfoHelper.privilegeWorkspace() && this.appServer.trainingId) {
                this.loadTraining(this.appServer);
            }
            // if (!this.isAws()) {
            //     this.loadVolumeInfo();
            //     this.loadVolumeSizeLimit();
            // }
        }
        this.loadMtextLogLastModified();
    }

    private loadFromLocalStorage() {
        const serverName = localStorage.getItem('serverDetail.serverName');

        if (serverName === this.appServer.serverName) {
            this.isDbToolsActive = JSON.parse(localStorage.getItem('serverDetail.isDbToolsActive'));
            this.isDocumentPortalActive = JSON.parse(localStorage.getItem('serverDetail.isDocumentPortalActive'));
            this.isWelcomePageSecureAvaible = JSON.parse(localStorage.getItem('serverDetail.isWelcomePageSecureAvaible'));
            this.volumeSpace = JSON.parse(localStorage.getItem('serverDetail.volumeSpace'));
            this.maxVolumeSpace = JSON.parse(localStorage.getItem('serverDetail.maxVolumeSpace'));
            this.debugPort = JSON.parse(localStorage.getItem('serverDetail.debugPort'));
            this.jbossConsolePort = JSON.parse(localStorage.getItem('serverDetail.jbossConsolePort'));
            this.jbossPort = JSON.parse(localStorage.getItem('serverDetail.jbossPort'));
            this.mtextLogLastModified = JSON.parse(localStorage.getItem('serverDetail.mtextLogLastModified'));
            this.whitelistSourceRange = JSON.parse(localStorage.getItem('serverDetail.whitelistSourceRange'));
            this.runningTime = JSON.parse(localStorage.getItem('serverDetail.runningTime'));
        } else {
            localStorage.setItem('serverDetail.serverName', this.appServer.serverName);
        }

    }

    openPage(url: string) {
        window.open('https://' + url);
    }

    copyToClipboard(val: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
      }

    copyExecCommandToClipboard(podName: string, namespace: string, container: string) {
        const execCommand = 'kubectl exec ' + podName + ' -n ' + namespace + ' -c ' + container + ' -it -- bash';
        this.copyToClipboard(execCommand);
        this.popUpHelper.showPopUp('Exec command has been copied to clipboard');
    }

    copyLogCommandToClipboard(podName: string, namespace: string, container: string) {
        const logCommand = 'kubectl logs ' + podName + ' -n ' + namespace + ' -c ' + container;
        this.copyToClipboard(logCommand);
        this.popUpHelper.showPopUp('Log command has been copied to clipboard');
    }

    clearVolumeData() {
        const dialogRef = this.openSubmitDialog('Confirm volume data cleanup',
            'Data stored on the volume (path: /home/seriem/server/) will be deleted.');
        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                this.volumeService.cleanVolume(this.appServer).subscribe(
                    data => this.popUpHelper.showPopUp('Volume was cleaned'),
                    error => this.popUpHelper.showErrorPopUp('App server suspend error', error)
                );
            }
        })
    }

    checkDbToolsActive() {
        this.appServerOtherDetailsService.isDbToolsActive(this.appServer).subscribe(
            data => {
                this.isDbToolsActive = data;
                localStorage.setItem('serverDetail.isDbToolsActive', this.isDbToolsActive + '');
            },
            err => {
                this.dialog.open(InfoDialogComponent, {
                    width: '450px',
                    data: { "title" :"Error", "message": err},
                  });            }

        )
    }

    checkDocumentPortalActive() {
        this.appServerOtherDetailsService.isDocumentPortalActive(this.appServer).subscribe(
            data => {
                this.isDocumentPortalActive = data;
                localStorage.setItem('serverDetail.isDocumentPortalActive', this.isDocumentPortalActive + '');
            },
            err => {
                this.dialog.open(InfoDialogComponent, {
                    width: '450px',
                    data: { "title" :"Error", "message": err},
                  });            }

        )
    }

    secureWelcomePage() {
        this.popUpHelper.showLoadingPopUp('Welcome page security in progress');
        this.appServerOtherDetailsService.secureWelcomePage(this.appServer).subscribe(data => {
            this.popUpHelper.showPopUp('Welcome page will be secured');
            this.openInfoDialog('Welcome page security', 'Wildfly container will be restarted in a few seconds. Then the welcome page will be secured.')
        },
        error => this.popUpHelper.showErrorPopUp('Secure welcome page failed', error))
    }

    unsecureWelcomePage() {
        this.popUpHelper.showLoadingPopUp('Welcome page security disable in progress');
        this.appServerOtherDetailsService.unsecureWelcomePage(this.appServer).subscribe(data => {
            this.popUpHelper.showPopUp('Welcome page unsecured');
            this.appServer.secureWelcomePage = false;
        },
        error => this.popUpHelper.showErrorPopUp('Secure welcome page failed', error))
    }

    chechIsWelcomePageSecureAvaible() {
        this.appServerOtherDetailsService.isSecureWelcomePageSAvaible(this.appServer).subscribe(
            data => {
                this.isWelcomePageSecureAvaible = data;
                localStorage.setItem('serverDetail.isWelcomePageSecureAvaible', this.isWelcomePageSecureAvaible + '');
            },
            err => {
                this.dialog.open(InfoDialogComponent, {
                    width: '450px',
                    data: { "title" :"Error", "message": err},
                  });            }

        )
    }

    activateDocumentPortal() {
        this.popUpHelper.showLoadingPopUp('Document portal activation running');
        this.appServerOtherDetailsService.activateDocumentPortal(this.appServer).subscribe(data => {
            this.popUpHelper.showPopUp('Document portal activated');
            this.isDocumentPortalActive = true;
        },
        error => this.popUpHelper.showErrorPopUp('Document portal activation failed', error))
    }

    deactivateDocumentPortal() {
        this.popUpHelper.showLoadingPopUp('Document portal deactivation running');
        this.appServerOtherDetailsService.deactivateDocumentPortal(this.appServer).subscribe(data => {
            this.popUpHelper.showPopUp('Document portal deactivated');
            this.isDocumentPortalActive = false;
        },
        error => this.popUpHelper.showErrorPopUp('Document portal activation failed', error))
    }

    activateDbTools() {
        this.popUpHelper.showLoadingPopUp('DB tools activation running');
        this.appServerOtherDetailsService.activateDbTools(this.appServer).subscribe(data => {
            this.popUpHelper.showPopUp('DB tools activated');
            this.isDbToolsActive = true;
        },
        error => this.popUpHelper.showErrorPopUp('DB tools activation failed', error))
    }

    deactivateDbTools() {
        this.popUpHelper.showLoadingPopUp('DB tools deactivation running');
        this.appServerOtherDetailsService.deactivateDbTools(this.appServer).subscribe(data => {
            this.popUpHelper.showPopUp('DB tools deactivated');
            this.isDbToolsActive = false;
        },
        error => this.popUpHelper.showErrorPopUp('DB tools activation failed', error))
    }

    getServerLog() {
        this.appServerService.getServerLog(this.appServer).subscribe(
            data => {
                this.popUpHelper.showPopUp('Server log file started downloading');
                saveFile(data, this.appServer.serverName + '.log');
            },
            error => this.popUpHelper.showErrorPopUp('Server log file error', error)
        );
    }

    private openInfoDialog(title: string, message: string): void {
        this.dialog.open(InfoDialogComponent, {
            width: '450px',
            data: { title, message }
        });
    }



    openServerBackupListDialog() {
        return this.dialog.open(ServerBackupListComponent, {
            width: '500px',
            data: this.appServer,
            panelClass: 'backup-list-dialog',
        });
    }

    private loadWhitelistSourceRange() {
        this.appServerOtherDetailsService.getWhitelistSourceRange(this.appServer).subscribe(
            data => {
                this.whitelistSourceRange = data
                localStorage.setItem('serverDetail.whitelistSourceRange', JSON.stringify(this.whitelistSourceRange));
            }
        );
    }

    private loadMtextLogLastModified() {
        this.appServerOtherDetailsService.getMtextLogLastModified(this.appServer).subscribe( data => {
            if (data !== null) {
                this.mtextLogLastModified = new Date(data);
                localStorage.setItem('serverDetail.mtextLogLastModified', JSON.stringify(this.mtextLogLastModified));
            } else {
                this.mtextLogLastModified = null;
            }
        });
    }

    private loadPorts() {
        this.appServerOtherDetailsService.getPorts(this.appServer).subscribe(data => {
            this.debugPort = data.debugingPort;
            this.jbossConsolePort = data.jbossConsolePort;
            this.jbossPort = data.jbossPort;
            localStorage.setItem('serverDetail.debugPort', JSON.stringify(this.debugPort));
            localStorage.setItem('serverDetail.jbossConsolePort', JSON.stringify(this.jbossConsolePort));
            localStorage.setItem('serverDetail.jbossPort', JSON.stringify(this.jbossPort));
        });
    }

    private loadVolumeSizeLimit() {
        this.volumeService.getVolumeSizeLimit().subscribe(
            data => {
                this.maxVolumeSpace = data;
                localStorage.setItem('serverDetail.maxVolumeSpace', JSON.stringify(this.maxVolumeSpace));
            }
        );
    }

    private loadVolumeInfo() {
        this.volumeService.getVolume(this.appServer).subscribe(
            data => {
                this.volumeSpace = data;
                localStorage.setItem('serverDetail.volumeSpace', JSON.stringify(this.volumeSpace));
            }
        );
    }

    private isWelcomePageSecure() {
        this.appServerOtherDetailsService.isWelcomePageSecure(this.appServer).subscribe(data => this.appServer.secureWelcomePage = data);
    }

    isAws() {
        return environment.aws;
    }

    private openSubmitDialog(title: string, message: string): MatDialogRef<SubmitDialogComponent, any> {
        return this.dialog.open(SubmitDialogComponent, {
            width: '450px',
            data: { title, message }
        });
    }

    public getRunningTime(): string{
        const ms = this.appServer.podInfo.runningTime;
        const days = Math.floor(ms / (24 * 60 * 60 * 1000));
        const daysms = ms % (24 * 60 * 60 * 1000);
        const hours = Math.floor((daysms) / (60 * 60 * 1000));
        const hoursms = ms % (60 * 60 * 1000);
        const minutes = Math.floor((hoursms) / (60 * 1000));
        const minutesms = ms % (60 * 1000);
        const sec = Math.floor((minutesms) / (1000));
        if (days > 0) {
            return days + ' days, ' + hours + ' hours';
        } else {
            return hours + ' hours, ' + minutes + ' minutes, ' + sec + ' seconds';
        }
    }

    loadTraining(appServer: AppServer) : void {
         this.trainingService.getTrainingById(appServer.trainingId).subscribe(
            (training) => {
                this.training = training;
            },
            (error) => {
                this.popUpHelper.showErrorPopUp('There was an error loading trainings!', error.error)
            }
         ); 
    }

    checkHasWorkspace() : boolean {
        return (this.training !== null && this.training != undefined) && (this.training.workspaceUser !== null && this.training.workspaceUser !== undefined);
    }
}
